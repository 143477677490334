import type {
  CategorySchema,
  SubCategorySchema,
} from '@/contentful/contentful-schema-types'

import { groupBy, uniqBy } from '@/lib/utils'

const sanitizeUrlSegment = (segment: string): string =>
  segment
    .toLowerCase()
    .replace(/\//g, '-')
    .replace(/[&\s]+/g, '-')
    .replace(/--+/g, '-')
    .replace(/^-|-$/g, '')
    .trim()

const removeLeadingSlash = (path: string): string => path.replace(/^\//, '')

/**
 * filter subcategories removing another twin subcategory by prioritizing the one with mainCategory
 *
 * @param subcategories
 * @param mainCategory
 */
export function uniqSubcategoriesByMainCategory(
  subcategories: SubCategorySchema[],
  mainCategory?: CategorySchema
) {
  const groupedBySlug = groupBy(subcategories, (item) => item.fields.slug)
  const uniqBySlug = uniqBy(subcategories, (item) => item.fields.slug)

  return uniqBySlug.map((category) => {
    return (
      groupedBySlug[category.fields.slug].find((sub) => {
        return sub.fields.category?.sys.id === mainCategory?.sys.id
      }) || groupedBySlug[category.fields.slug][0]
    )
  })
}

export const contentConfig = {
  categoryPath: (category: CategorySchema) =>
    `/categories/${sanitizeUrlSegment(category.fields.slug)}`,
  subcategoryPath: (subcategory: SubCategorySchema) =>
    `/categories/${sanitizeUrlSegment(subcategory.fields.category?.fields.slug || '')}/subcategories/${sanitizeUrlSegment(subcategory.fields.slug)}`,
  articlePath: (articleSlug: string) =>
    `/articles/${removeLeadingSlash(articleSlug)}`,
}
